import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const parseDateString = (value: any, originalValue: any): Date => {
    const parsedDate = Date.parse(originalValue);
    return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
};

// Get the current date and set the time to 00:00:00 to ensure only the date part is compared
const today = new Date();
today.setHours(0, 0, 0, 0);

const verifyOTPSchema = Yup.object().shape({
    otp: Yup.string().required('Required'),
});


const userSchema = Yup.object().shape({

    firstName: Yup.string().required('First name is required.')
    .matches(/^[A-Za-z\s]+$/, 'First Name contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'First name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),

    lastName: Yup.string().required('Last name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Last name contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'Last name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),

    phoneNumber:
        Yup
            .string()
            .required("Phone number is required.")
            .test('phone-number', function (value) {
                if (value) {
                    if (!isPossiblePhoneNumber(value as string) || !isValidPhoneNumber(value as string)) {
                        return this.createError({ message: 'Invalid mobile number', path: this.path });
                    }
                    return true;
                }
                return true;
            }),

    email: Yup.string().required('Email is required.').email(),

    isEmailVerified: Yup.boolean().required('Email is not verified!'),

    isPhoneNoVerified: Yup.boolean().required('Phone number is not verified!'),

    handsetModel: Yup.string().required('Handset model name is required.')
    .matches(/^[A-Za-z0-9\s]+$/, 'Handset model name contains alphabetic characters & digits')
    .max(25)
    .test('no-whitespace', 'Last name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),
    travellingCountry: Yup.string()
        .required('Please enter country name')
        // .matches(/^[A-Za-z\s]+$/, 'Name contains only alphabetic characters')
        .max(25)
        .test('no-whitespace', 'Full name should not start or end with whitespace', value => {
            if (value) {
                return value === value.trim();
            }
            return true;
        }),
        travellingDate: Yup.date()
        // .notRequired()
        .required('Date is required.')
        .typeError('Invalid date format')
        .transform(parseDateString)
        .min(today, 'Travelling date must be today or later')
});

export {
    verifyOTPSchema,
    userSchema
};

