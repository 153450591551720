import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";


export const sendOtp = async (params:any) => {
    return axios.post(`${API_URL}/otp/send`, params);
}

export const otpVeriFy = async (params:any) => {
    return axios.post(`${API_URL}/otp/verify`, params);
}

export const createUser = async (params:any) => {
    return axios.post(`${API_URL}/vfsPhillip/create-vfsPhillip-user`, params);
}

export const getCart = async (id:string) => {
    return axios.get(`${API_URL}/carts/${id}`);
}

export const getFilter = async (params:any) => {
    return axios.get(`${API_URL}/vfsPhillip/esim-plan/filter`, {params});
}
export const addToCart = async (id:string, items:any) => {
    return axios.put(`${API_URL}/carts/${id}`, items);
}


export const createOrder = async (params:any) => {
    return axios.post(`${API_URL}/vfsPhillip/vfsPhillip-order`, params);
}


export const stripePaymentIntent = async ( params: any) => {
    return await axios.post(`${API_URL}/stripe/paymentIntent`, params);
};

export const purchaseOrder = async (params: any): Promise<any> => {
    return axios.post(`${API_URL}/order/purchaseZeroPriceOrder`, params);
};