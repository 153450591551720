import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import data from "../../assets/images/data.svg";
import validity from "../../assets/images/validity.svg";
import speed from "../../assets/images/speed.svg";
import payone from "../../assets/images/payone.svg";
import paytwo from "../../assets/images/paytwo.svg";
import paymentsucess from "../../assets/images/paymentsucess.svg";
import { useFormik } from "formik";
import { userSchema } from "./validations";
import styles from "./styles/style.module.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getbundles, getCountries } from "../vfsuk/api";
import DatePicker from "react-datepicker";
import {
  addToCart,
  createOrder,
  createUser,
  getCart,
  getFilter,
  otpVeriFy,
  purchaseOrder,
  sendOtp,
  stripePaymentIntent,
} from "./api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../lib/useAuth";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";
import Pagination from "../pagination";

function Checkout() {
  //   const [] = useState();
  const { id } = useParams();
  const { isAuthenticated, loginUser, logout } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const countryId: any = queryParams.get("country");

  const [otpShow, setOtpShow] = useState({
    otp: false,
    otpId: "",
    otpVal: "",
    params: "",
    phoneNumber: "",
    email: "",
  });
  const [userPopup, setUserPopup] = useState(false);
  const [cartUpdate, setCartUpdate] = useState(false);
  const [isPhoneNoVerified, setPhoneNoVerified] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [countriesData, setCountriesData] = useState<any>({
    items: [],
    count: 0,
  });
  const [token, setToken] = useState("");
  const [cartData, setCartData] = useState<any>("");
  const [filterData, setFilterData] = useState<any>("");
  const [filterValues, setFilterValues] = useState<any>({
    validityDays: "",
    dataAmount: "",
  });
  const [bundlesData, setBundlesData] = useState<any>({ items: [], count: 0 });
  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    parPageRecords: 5,
  });
  const [show, setShow] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<any>({
    clientSecret: "",
    paymentId: "",
    setupIntent: "",
    currency: "",
    label: "Total",
    amount: 0,
  });

  const [paymentMethod, setPaymentMethod] = useState(1);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
  );
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);

  const formik: any = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      handsetModel: "",
      travellingCountry: "",
      travellingDate: "",
      isEmailVerified,
      isPhoneNoVerified,
    },

    validationSchema: userSchema,

    onSubmit: async (values, { setFieldError }) => {
      if (
        !isPossiblePhoneNumber(values.phoneNumber) ||
        !isValidPhoneNumber(values.phoneNumber)
      ) {
        setFieldError("phoneNumber", "Invalid phone number");
        return;
      }

      const parsedNumber = parsePhoneNumber(values.phoneNumber);

      let payload: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        handsetModel: values.handsetModel,
        countryCode: `+${parsedNumber?.countryCallingCode as string}`,
        phoneNumber: parsedNumber?.nationalNumber as string,
        isEmailVerified,
        isPhoneNoVerified,
        deviceType: 1,
        destinationTravellingCountryId: values.travellingCountry,
        destinationDate : values.travellingDate
      };
      // console.log(payload," payload----------------")

      if (!payload.isPhoneNoVerified && !payload.isEmailVerified) {
        formik.errors.phoneNumber = "Phone is not verified!";
        formik.errors.email = "Email is not verified!";
        return null;
      }

      if (!payload.isPhoneNoVerified) {
        formik.errors.phoneNumber = "Phone is not verified!";
        return null;
      }

      if (!payload.isEmailVerified) {
        formik.errors.email = "Email is not verified!";
        return null;
      }

      toast.promise(createUser(payload), {
        pending: {
          render() {
            return "Trying to register user";
          },
        },
        success: {
          render({ data }: any) {
            setToken(data.accessToken);
            loginUser(data.accessToken);
            // setUserPopup(false)
            return "User register successfully";
          },
        },
        error: {
          render({ data }: any) {
            return data?.data?.message || "Something wents wrong!";
          },
        },
      });
    },
  });

  useEffect(() => {
    getCountries({
      page: 1,
      limit: 300,
    })
      .then((res: any) => {
        
        setCountriesData({
          items: res?.data?.countries,
          count: res?.data?.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    getFilter({ search: "Europe" })
      .then((res: any) => {
        setFilterData(res?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //wdwdwdw
  useEffect(() => {
    if (token)
      createOrder({ cartId: id })
        .then((res: any) => {
          if (res?.totalAmount === 0) {
            purchaseOrder({ orderId: res?.orderId, status: 0 })
              .then((res: any) => {
                setShow(false);
                setUserPopup(false);
                setPaymentSuccess(true);
              })
              .catch((error: any) => {
                // setShow(true);
                toast.error(error?.data?.message || "Something went wrong!");
              });
          } else {
            stripePaymentIntent({ orderId: res?.orderId, isVFS: true })
              .then((res: any) => {
                setPaymentDetails({
                  clientSecret: res.data.paymentIntentSecretKey,
                  paymentId: res.data.paymentId,
                  setupIntent: res.data.setupIntent,
                  currency: res.data.currency.toLowerCase(),
                  label: "Total",
                  amount: res.data.amount,
                });
                setShow(true);
                setUserPopup(false);
              })
              .catch((error: any) => {
                // setShow(true);
                toast.error(error?.data?.message || "Something went wrong!");
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, [isAuthenticated, token]);

  useEffect(() => {
    getCart(id as string)
      .then((res: any) => {
        setCartData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cartUpdate]);

  useEffect(() => {
    const query: any = {
      page: pagination?.currentPage,
      limit: pagination?.parPageRecords,
    };

    if (countryId) query.countryId = countryId;

    if (filterValues?.validityDays) query.duration = filterValues?.validityDays;
    if (filterValues?.dataAmount) query.dataAmount = filterValues?.dataAmount;

    getbundles(query)
      .then((res: any) => {
        setBundlesData({ items: res?.data, count: res?.count });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pagination, filterValues, countryId]);

  // useEffect(() => {
  //   if (cartData?.items?.length > 0 && bundlesData?.items?.length > 0) {
  //     const filteredBundles = bundlesData.items.filter(
  //       (bundle: any) =>
  //         !cartData.items.some((cartItem: any) => cartItem.bundleId === bundle._id)
  //     );
  
  //     setBundlesData((prev: any) => ({
  //       ...prev,
  //       items: filteredBundles,
  //     }));
  //   }
  // }, [cartData, bundlesData]);
  
  const handleSendOtpCode = (params: string) => {
    const otpPayload: any = {
      type: 4,
    };

    if (params == "phoneNumber") {
      const phoneNumber: any = formik.values.phoneNumber;
      const parsedNumber = parsePhoneNumber(phoneNumber);

      if (parsedNumber?.nationalNumber && parsedNumber?.countryCallingCode) {
        otpPayload.phoneNumber = parsedNumber.nationalNumber as string;
        otpPayload.countryCode = `+${parsedNumber.countryCallingCode as string
          }`;
      }
    } else {
      const email: any = formik.values.email;
      otpPayload.email = email;
    }

    toast.promise(sendOtp(otpPayload), {
      pending: {
        render() {
          return "Trying to send otp";
        },
      },
      success: {
        render({ data }) {
          setOtpShow((pre: any) => ({
            ...pre,
            otpId: data?.data?.otpId,
            otp: true,
            otpVal: "",
            params,
          }));
          return "OTP sent successfully";
        },
      },
      error: {
        render({ data }: any) {
          return data.data.message;
        },
      },
    });
  };

  const handleVerifyOtp = () => {
    if (!otpShow?.otpVal) return toast.error("Please enter vaild OTP");
    const payload = {
      otp: otpShow?.otpVal,
      type: 4,
      otpId: otpShow?.otpId,
    };

    toast.promise(otpVeriFy(payload), {
      pending: {
        render() {
          return "Trying to verify otp";
        },
      },
      success: {
        render({ data }) {
          let otpData: any;
          if (otpShow?.params === "email") {
            setIsEmailVerified(true);
            otpData = { email: formik.values.email };

            setIsEmailVerified(true);
          } else {
            setPhoneNoVerified(true);
            otpData = { phoneNumber: formik.values.phoneNumber };
            setPhoneNoVerified(true);
          }
          setOtpShow((pre: any) => ({
            ...pre,
            otpId: data?.data?.otpId,
            otp: false,
            otpVal: "",
            ...otpData,
          }));
          return "OTP verify successfully";
        },
      },
      error: {
        render({ data }: any) {
          return data.data.message;
        },
      },
    });
  };

  const handelAddToCart = (params: any) => {
    toast.promise(addToCart(id as string, params), {
      pending: {
        render() {
          return params?.type === 1
            ? "Trying to add item"
            : "Trying to remove item";
        },
      },
      success: {
        render({ data }: any) {
          setCartUpdate(!cartUpdate);
          return data?.message;
        },
      },
      error: {
        render({ data }: any) {
          return data.data.message || "Something wents wrong!";
        },
      },
    });
  };

  const options = {
    clientSecret: paymentDetails?.clientSecret,
  };

  useEffect(() => {
    if (paymentSuccess) {
      logout();
      setToken("");
      setTimeout(() => {
        navigate(`/`);
      }, 5000);
    }
  }, [paymentSuccess]);

  const handlePageChange = (page: number) => {
    setPagination((pre: any) => ({ ...pre, currentPage: page }));
  };

  return (
    <>
      <div className="vfsukbaneer">
        <div className="container">
          <h3>Almost There! </h3>
          <p>
            Fill out the form below to unlock your free data and stay connected
            on your
            <br /> travels. It's quick and easy!
          </p>
        </div>
      </div>
      <div className="checkoutProcess">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="leftchatProces">
                <h3>Upgrade Your Plan</h3>
                <div className="plansList">
                  <h5>SIM card validity</h5>
                  <ul>
                    {filterData?.validityDays &&
                      filterData?.validityDays?.map((item: any) => (
                        <li
                          className={
                            filterValues?.validityDays === item ? "active" : ""
                          }
                          onClick={() =>
                            setFilterValues((pre: any) => ({
                              ...pre,
                              validityDays: item,
                            }))
                          }
                        >
                          <span>
                            {item}
                            {item == 1 ? " Day" : " Days"}
                          </span>
                        </li>
                      ))}
                  </ul>
                  <h5>Data package</h5>
                  <ul>
                    {filterData?.dataAmount &&
                      filterData?.dataAmount?.map((item: any) => (
                        <li
                          className={
                            filterValues?.dataAmount === item ? "active" : ""
                          }
                          onClick={() =>
                            setFilterValues((pre: any) => ({
                              ...pre,
                              dataAmount: item,
                            }))
                          }
                        >
                          <span>
                            {item == -1
                              ? "Unlimited"
                              : item / 1000 >= 1
                                ? item / 1000 + " GB"
                                : item / 1000 + " MB"}
                          </span>
                        </li>
                      ))}
                  </ul>
                  <div className="palnsDetails">
                    {cartData &&
                      cartData?.items?.map((item: any, index: number) => (
                        <div className="ukPlansOuter">
                          <div className="ukplanHead">
                            <div className="ukplanHeadleft">
                              <h4>{item?.name}</h4>
                            </div>
                            <div className="ukplanHeadright">
                              <span>
                                {item?.priceSymbol} {item?.price}
                              </span>
                            </div>
                          </div>
                          <div className="newpatren">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={data} alt="" />
                                    Data
                                  </span>
                                  <h4>
                                    {item?.dataAmount == -1
                                      ? "Unlimited"
                                      : item?.dataAmount / 1000 < 1
                                        ? item?.dataAmount?.toFixed(3) + " MB"
                                        : item?.dataAmount / 1000 + " GB"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={validity} alt="" />
                                    Validity
                                  </span>
                                  <h4>
                                    {item?.duration <= 1
                                      ? item?.duration + " Day"
                                      : item?.duration + " Days"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={speed} alt="" />
                                    Speed
                                  </span>
                                  <h4>
                                    {item?.speed && item.speed.length > 0
                                      ? item.speed.slice(-1)
                                      : "High Speed"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <span
                              className={`added ${!item?.scratchType ? "" : "disabled"
                                }`}
                              onClick={(e) => {
                                if (item?.scratchType) return; // Prevent click if disabled
                                e.preventDefault();
                                handelAddToCart({
                                  items: [
                                    {
                                      bundleId: item?.bundleId,
                                      quantity: 1,
                                      price: Number(item?.price),
                                    },
                                  ],
                                  type: 2,
                                });
                              }}
                            >
                              Remove
                            </span>
                          </div>
                        </div>
                      ))}

                    {bundlesData?.items && bundlesData?.items?.length > 0 ? (
                      bundlesData?.items?.map((item: any, index: number) => (
                        <div className="ukPlansOuter" key={index}>
                          <div className="ukplanHead">
                            <div className="ukplanHeadleft">
                              <h4>{item?.name}</h4>
                              {/* <h5>Country</h5> */}
                            </div>
                            <div className="ukplanHeadright">
                              <span>
                                {item?.priceSymbol} {item?.price}
                              </span>
                            </div>
                          </div>
                          <div className="newpatren">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={data} alt="" />
                                    Data
                                  </span>
                                  <h4>
                                    {item?.dataAmount == -1
                                      ? "Unlimited"
                                      : item?.dataAmount / 1000 < 1
                                        ? item?.dataAmount?.toFixed(3) + " MB"
                                        : item?.dataAmount / 1000 + " GB"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={validity} alt="" />
                                    Validity
                                  </span>
                                  <h4>
                                    {item?.duration <= 1
                                      ? item?.duration + " Day"
                                      : item?.duration + " Days"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="planinner">
                                  <span>
                                    <img src={speed} alt="" />
                                    Speed
                                  </span>
                                  <h4>
                                    {item?.speed && item.speed.length > 0
                                      ? item.speed.slice(-1)
                                      : "High Speed"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handelAddToCart({
                                  items: [
                                    {
                                      bundleId: item?._id,
                                      quantity: 1,
                                      price: Number(item?.price),
                                    },
                                  ],
                                  type: 1,
                                });
                              }}
                            >
                              Add to Cart
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="ukPlansOuter">
                        <div className="newpatren">
                          <div className="row">
                            <div className="col-md-4">
                              <h4>eSim not found.</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {bundlesData?.items?.length > 0 && (
                      <Pagination
                        count={bundlesData?.count} // You can replace this with the actual count received from the API
                        handlePageChange={handlePageChange}
                        currentPage={pagination?.currentPage}
                        itemsPerPage={pagination?.parPageRecords}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rightchatProces">
                <div className="rightCheckout">
                  <h3>Secure Checkout</h3>
                  {/* <div className="row">
                    <div className="col-md-4">
                      <div className="planinner">
                        <span>
                          <img src={data} alt="" />
                          Data
                        </span>
                        <h4>1GB</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="planinner">
                        <span>
                          <img src={validity} alt="" />
                          Validity
                        </span>
                        <h4>7 Days</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="planinner">
                        <span>
                          <img src={speed} alt="" />
                          Speed
                        </span>
                        <h4>4G/5G</h4>
                      </div>
                    </div>
                  </div> */}
                  <div className="planSummary">
                    <h4>Payment Summary</h4>
                    <ul>
                      {/* <li>
                        1GB Data <span>$4.25</span>
                      </li> */}
                      <li>
                        Subtotal <span> {cartData?.priceSymbol} {cartData?.subTotal}</span>
                      </li>
                      <li>
                        Discount <span>{cartData?.priceSymbol}{cartData?.discount}</span>
                      </li>
                      <li>
                        Total <span>{cartData?.priceSymbol}{cartData?.totalAmount}</span>
                      </li>
                    </ul>
                    <button
                      onClick={() => {
                        token ? setShow(true) : setUserPopup(true);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {userPopup && (
        <div
          className="modal fade show"
          id="personalDetails"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setUserPopup(false)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="detailsPersoanl">
                  <h3>Get Your 2GB Data in Minutes</h3>
                  <p>
                    Fill in your details and start enjoying seamless
                    connectivity!
                  </p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="formGroup">
                          <label>First Name*</label>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Enter first name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName && (
                              <div className={styles.error}>
                                {formik.errors.firstName}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGroup">
                          <label>Last Name*</label>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Enter last name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                          />
                          {formik.touched.lastName &&
                            formik.errors.lastName && (
                              <div className={styles.error}>
                                {formik.errors.lastName}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGroup">
                          <label>Phone Number*</label>
                          <PhoneInput
                            placeholder="input your Number here"
                            onChange={(value) => {
                              formik.setFieldValue("phoneNumber", value);
                              if (otpShow?.phoneNumber === value) {
                                setPhoneNoVerified(true);
                              } else {
                                setPhoneNoVerified(false);
                              }
                            }}
                            value={formik.values.phoneNumber}
                          />
                          <span
                            onClick={() => {
                              if (!formik.values.phoneNumber) {
                                formik.setFieldError(
                                  "phoneNumber",
                                  "Phone number is required."
                                );
                                return;
                              }
                              if (
                                formik.errors.phoneNumber ==
                                "Invalid mobile number"
                              )
                                return false;

                              if (isPhoneNoVerified) return false;

                              handleSendOtpCode("phoneNumber");
                            }}
                          >
                            {isPhoneNoVerified ? "Verified" : "Verify"}
                          </span>
                          {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber && (
                              <div className={styles.error}>
                                {formik.errors.phoneNumber}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="formGroup">
                          <label>Email*</label>
                          <input
                            type="text"
                            name="email"
                            placeholder="input your email here "
                            onChange={(e: any) => {
                              formik.setFieldValue("email", e.target.value);
                              if (otpShow?.email === e.target.value) {
                                setIsEmailVerified(true);
                              } else {
                                setIsEmailVerified(false);
                              }

                              // formik.handleChange
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          // readOnly={!emailVerified}
                          />
                          <span
                            onClick={() => {
                              if (!formik.values.email) {
                                formik.setFieldError(
                                  "email",
                                  "Email is required"
                                );
                                return;
                              }
                              if (
                                formik.errors.email ==
                                "email must be a valid email"
                              )
                                return false;
                              if (isEmailVerified) return false;
                              handleSendOtpCode("email");
                            }}
                          >
                            {isEmailVerified ? "Verified" : "Verify"}
                          </span>
                          {formik.touched.email && formik.errors.email && (
                            <div className={styles.error}>
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formGroup">
                          <label>Handset Model Name*</label>
                          <input
                            type="text"
                            name="handsetModel"
                            placeholder="Please enter handset model name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.handsetModel}
                          />
                          {formik.touched.handsetModel &&
                            formik.errors.handsetModel && (
                              <div className={styles.error}>
                                {formik.errors.handsetModel}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formGroup">
                          <label>Traveling Country*</label>
                          <select
                            name="travellingCountry"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.travellingCountry}
                          >
                            <option value="">Select Country</option>
                            {countriesData?.items &&
                              countriesData?.items?.map((list: any) => (
                                <option value={list?._id}>{list?.name}</option>
                              ))}
                          </select>
                          {formik.touched.travellingCountry &&
                            formik.errors.travellingCountry && (
                              <div className={styles.error}>
                                {formik.errors.travellingCountry}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="formGroup">
                          <label>Date*</label>
                          <DatePicker
                            selected={
                              formik.values.travellingDate
                                ? new Date(formik.values.travellingDate)
                                : null
                            } // Ensure it's a Date object
                            onChange={(date) =>
                              formik.setFieldValue("travellingDate", date)
                            } // Set the date
                            name="travellingDate"
                            placeholderText="Select Travelling Date"
                            onBlur={formik.handleBlur}
                            dateFormat="dd-M-yyyy" // Optional
                            value={formik.values.travellingDate}
                          />
                          {formik.touched.travellingDate &&
                            formik.errors.travellingDate && (
                              <div className={styles.error}>
                                {formik.errors.travellingDate}
                              </div>
                            )}
                        </div>
                      </div>
                      {(formik.errors.phoneNumber ===
                        "Phone is not verified!" ||
                        formik.errors.email === "Email is not verified!") && (
                          <div className="col-md-12">
                            <div className="mymessage">
                              <h6>
                                Verify your phone number and email to continue and
                                unlock your 2GB data.
                              </h6>
                            </div>
                          </div>
                        )}

                      <div className="col-md-12">
                        <div className="submtbnt">
                          <button type="submit">Continue</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* opt modal */}
      {otpShow?.otp && (
        <div
          className="modal fade show"
          id="OTPverify"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setOtpShow((pre: any) => ({ ...pre, otp: false }));
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="otpVerfiy">
                  <h4>
                    Verify{" "}
                    {otpShow?.params === "email" ? "Email" : "Phone Number"}
                  </h4>
                  <p>
                    Ensure the Security of Your Account by Completing the{" "}
                    {otpShow?.params === "email" ? "Email" : "Phone Number"}{" "}
                    Verification Process
                  </p>
                  <OtpInput
                    value={otpShow?.otpVal}
                    onChange={(otp) =>
                      setOtpShow((pre: any) => ({ ...pre, otpVal: otp }))
                    }
                    numInputs={5}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />

                  <div className="verifyBtn">
                    <button type="submit" onClick={handleVerifyOtp}>
                      Verify OTP
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {show && (
        <div
          className="modal fade show"
          id="OTPverify"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="paymentSlect">
                  <h4>Choose Payment Method</h4>
                  <div className="paymntmode">
                    <ul>
                      {/* <li>
                        <img src={payone} alt="" />
                        Apple Pay
                      </li> */}
                      <li onClick={() => setPaymentMethod(1)}>
                        <img src={paytwo} alt="" />
                        Credit / Debit Card{" "}
                        {paymentMethod > 0 && <span>Selected</span>}
                      </li>
                    </ul>
                  </div>
                  {paymentMethod > 0 && (
                    <Elements stripe={stripePromise} options={options}>
                      <StripeForm
                        setPaymentSuccess={setPaymentSuccess}
                        setShow={setShow}
                        clientSecret={paymentDetails?.clientSecret}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {paymentSuccess && (
        <div
          className="modal fade show"
          id="OTPverify"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    logout();
                    setToken("");
                    navigate(`/`);
                    setPaymentSuccess(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="otpVerfiy payemntSucess">
                  <img src={paymentsucess} alt="" />
                  <h4>Payment Successful!</h4>
                  <p>
                    Thank you for making a purchase! Your order is being
                    processed immediately. We will notify you via email when
                    your order is ready to be dispatched. Thank you for your
                    patience!
                  </p>

                  <div className="verifyBtn">
                    <button type="submit">Proceed to Activate</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Checkout;
