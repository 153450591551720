import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";


export const getbundles = async (params:any) => {
    return axios.get(`${API_URL}/vfsPhillip/esim-plan`, {params});
}

export const couponVerify = async (params:any) => {
    return axios.post(`${API_URL}/vfsPhillip/scratch-coupon-verify`, params);
}

export const getCountries = async (params:any) => {
    return axios.get(`${API_URL}/regions/countries`, {params});
}


export const orderCreate = async (params:any) => {
    return axios.post(`${API_URL}/order`, params);
}

export const createCart = async (params:any) => {
    return axios.post(`${API_URL}/carts`, params);
}
