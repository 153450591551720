import React, { useEffect, useState } from "react";
import dolone from "../../assets/images/dolone.png";
import doltwo from "../../assets/images/doltwo.png";
import dolthree from "../../assets/images/dolthree.png";
import dolfour from "../../assets/images/dolfour.png";
import data from "../../assets/images/data.svg";
import validity from "../../assets/images/validity.svg";
import speed from "../../assets/images/speed.svg";
import sort from "../../assets/images/sort.png";
import flag from "../../assets/images/flags.png";
import {
  couponVerify,
  createCart,
  getbundles,
  getCountries,
  orderCreate,
} from "./api";
import Pagination from "../pagination";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Vfs() {
  const navigate = useNavigate();

  const [bundlesData, setBundlesData] = useState<any>({ items: [], count: 0 });
  const [countriesData, setCountriesData] = useState<any>({
    items: [],
    count: 0,
  });
  const [countriesFilterData, setCountriesFilterData] = useState<any>({
    items: [],
    count: 0,
  });

  const [pagination, setPagination] = useState<any>({
    currentPage: 1,
    parPageRecords: 12,
  });

  const [couponVal, setCuponVal] = useState<any>("");
  const [couponMsg, setCouponMsg] = useState({
    error: "",
    success: "",
  });
  const [couponId, setCouponId] = useState<string>("");
  const [searchCountry, setSearchCountry] = useState<string>("");

  const [freePopup, setFreePopup] = useState(false);

  useEffect(() => {
    getbundles({
      page: pagination?.currentPage,
      limit: pagination?.parPageRecords,
    })
      .then((res: any) => {
        setBundlesData({ items: res?.data, count: res?.count });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pagination]);

  useEffect(() => {
    const query: any = {
      page: 1,
      limit: 10,
    };
    if (searchCountry) query.searchString = searchCountry;

    getCountries(query)
      .then((res: any) => {
        setCountriesData({
          items: res?.data?.countries,
          count: res?.data?.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchCountry, couponId]);

  const handlePageChange = (page: number) => {
    setPagination((pre: any) => ({ ...pre, currentPage: page }));
  };

  const handleCouponSubmit = () => {
    if (!couponVal)
      return setCouponMsg({ error: "Please enter coupon code", success: "" });
    couponVerify({ code: couponVal })
      .then((res: any) => {
        if (res?.statusCode === 400) {
          setCouponMsg({ error: res?.message, success: "" });
        } else {
          setCouponId(res?.data?._id);
          setCouponMsg({ error: "", success: res?.message });
          setFreePopup(false);
          setTimeout(() => {
            setCouponMsg({ error: "", success: "" });
            setCuponVal("");
          }, 3000);
        }
      })
      .catch((data) => {
        setCouponId("");
        setCouponMsg({ error: data?.message, success: "" });
      });
  };

  const cartCreate = (countryId: string) => {
    getbundles({
      dataAmount: 1000,
      duration: 7,
      countryId,
    })
      .then((res: any) => {
        if (res?.data[0]?._id && res?.statusCode === 200) {
          createCart({
            items: [
              {
                bundleId: res?.data[0]?._id,
                quantity: 1,
                price: Number(res?.data[0]?.price),
              },
            ],
            scratchCoupanId: couponId,
            cartType: 2,
          })
            .then((res: any) => {
              if (res?.statusCode === 201 && res?.data?._id) {
                navigate(`/checkout/${res?.data?._id}?country=${countryId}`);
              }
            })
            .catch(() => { });
        }
        // setBundlesData({ items: res?.data, count: res?.count });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Sorry!! Plan not fount")
      });
  };
  return (
    <>
      <div className="vfsukbaneer">
        <div className="container">
          <h3>Travel Smarter with Commbitz eSIM!</h3>
          <p>
            Enjoy hassle-free connectivity worldwide. Get your eSIM with 2GB
            data today!
          </p>
        </div>
      </div>

      <div className="clainData">
        <div className="container">
          <div className="clainbk">
            <h3>Claim Your 2GB Data Now!</h3>
            <p>
              Start your journey with complimentary data. Explore our eSIMs to
              upgrade, top up, and enjoy seamless connectivity wherever you
              travel!
            </p>
            <a
              onClick={(e) => {
                e.preventDefault();
                setFreePopup(true);
              }}
            >
              Claim 2GB Data
            </a>
          </div>
        </div>
      </div>



      <div className="ukPlans">
        <div className="container">
          <h3>Find the Best eSIM Plan for Your Destination!</h3>
          <div className="row">
            {bundlesData?.items?.length > 0 &&
              bundlesData?.items.map((bundle: any, index: number) => (
                <div className="col-md-4" key={index}>
                  <div className="ukPlansOuter">
                    <div className="ukplanHead">
                      <div className="ukplanHeadleft">
                        <h4>{bundle?.name}</h4>
                        {/* <h5>Country</h5> */}
                      </div>
                      <div className="ukplanHeadright">
                        <span>
                          {bundle?.priceSymbol} {bundle?.price}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="planinner">
                          <span>
                            <img src={data} alt="" />
                            Data
                          </span>
                          <h4>
                            {bundle &&
                              (bundle?.dataAmount == -1
                                ? "Unlimited"
                                : bundle?.dataAmount < 1000
                                  ? bundle?.dataAmount + " MB"
                                  : bundle?.dataAmount / 1000 + " GB")}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="planinner">
                          <span>
                            <img src={validity} alt="" />
                            Validity
                          </span>
                          <h4>{bundle?.duration} Days</h4>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="planinner">
                          <span>
                            <img src={speed} alt="" />
                            Speed
                          </span>
                          <h4>
                            {bundle?.speed && bundle.speed.length > 0
                              ? bundle.speed.slice(-1)
                              : "High Speed"}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        setFreePopup(true);
                      }}
                    >
                      BUY NOW
                    </a>
                  </div>
                </div>
              ))}
            {bundlesData?.items?.length > 0 && (
              <Pagination
                count={bundlesData?.count} // You can replace this with the actual count received from the API
                handlePageChange={handlePageChange}
                currentPage={pagination?.currentPage}
                itemsPerPage={pagination?.parPageRecords}
              />
            )}
          </div>
        </div>
      </div>
      <div className="benefitBox">
        <div className="container">
          <h3>
            {/* Highlight the key benefits */}
            Few USP's Highlights:
          </h3>
          <div className="row">
            <div className="col-md-3">
              <div className="benifitshad">
                <img src={dolone} alt="" />
                <p>
                  <b>Global Coverage: </b> Enjoy high-speed connectivity in more than
                  190 countries worldwide.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="benifitshad">
                <img src={doltwo} alt="" />
                <p>
                  <b>Ease of Use:</b> Get connected instantly with our eSIM solutions
                  or select from a variety of physical SIM options.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="benifitshad">
                <img src={dolthree} alt="" />
                <p>
                  <b>Affordable Rates: </b> Commbitz offers competitive rates with no
                  surprise charges, giving you peace of mind and budget-friendly
                  options.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="benifitshad">
                <img src={dolfour} alt="" />
                <p>
                  <b> 24/7 Support: </b> Our team is here to assist you, no matter where
                  your travels take you. <br /> 5G Support - High Speed Coverage
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {couponId && (
        <div
          className="modal fade show"
          id="SimPlans"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCouponId("");
                    setSearchCountry("");
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="changecountry">
                  <h3>Select your destination country</h3>
                  <div className="filters">
                    <div className="leftfilter">
                      <input
                        type="text"
                        placeholder="Search Country"
                        value={searchCountry}
                        onChange={(e) => setSearchCountry(e.target.value)}
                      />
                    </div>
                    <div className="rightfilter">
                      {/* <div className="formGroup">
                        <span>
                          <img src={sort} alt="" />
                        </span>
                        <select>
                          <option>Regions</option>
                          <option>Regions</option>
                        </select>
                      </div> */}
                      {/* <div className="formGroup">
                        <select>
                          <option>Country</option>
                          <option>Country</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                  <div className="countryList">
                    <ul>
                      {countriesData?.items?.length > 0 ? (
                        countriesData?.items?.map(
                          (item: any, index: number) => (
                            <li
                              key={index}
                              onClick={() => cartCreate(item?._id)}
                            >
                              <img src={item?.flagImageUrl} alt="" />
                              <span>{item?.name}</span>
                            </li>
                          )
                        )
                      ) : (
                        <div> Country not found. </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* next popup */}
      {freePopup && (
        <div
          className="modal fade show"
          id="alphacode"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  value={couponVal}
                  onClick={() => {
                    setCouponMsg({ error: "", success: "" });
                    setCuponVal("");
                    setFreePopup(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="changecountry">
                  <h3>Enter 6 digit code</h3>
                  <div className="alphacodefield">
                    <input
                      type="text"
                      placeholder="Enter Code"
                      onChange={(e) => {
                        setCouponMsg({ error: "", success: "" });
                        setCuponVal(e.target.value);
                      }}
                    />
                    {(couponMsg?.error || couponMsg?.success) && (
                      <div className={couponMsg?.error ? "error" : "success"}>
                        {" "}
                        {couponMsg?.error
                          ? couponMsg?.error
                          : couponMsg?.success}{" "}
                      </div>
                    )}
                    <button onClick={handleCouponSubmit}>Continue</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Vfs;
