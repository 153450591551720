import React, { useState } from 'react';
import { useStripe, useElements, CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import styles from "./style/style.module.css";
// import { updateStripePayment } from './api';
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import useStripeOptions from '../../lib/hooks/useStripeOptions';
// import { NIYO_URL } from '../../../config';

interface Props {
    clientSecret: string;
    setPaymentSuccess: (value: boolean) => void;
    setShow: (value: boolean) => void;
}

const StripeForm: React.FC<Props> = ({ clientSecret, setPaymentSuccess, setShow }) => {
    const stripe = useStripe();
    const stripeElements = useElements();
    const stripeOptions = useStripeOptions();
    const [error, setError] = useState<string | null>(null);
    

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !stripeElements) {
            return;
        }

        const cardElement = stripeElements.getElement(CardNumberElement);
        if (!cardElement) {
            return;
        }

        try {
            // Assuming `stripe` and `cardElement` are already initialized and available in your context
            const promise: any = stripe.confirmCardPayment(
                clientSecret,
                {
                    payment_method: {
                        card: cardElement as StripeCardNumberElement,
                    },
                },
                {
                    handleActions: true, // Disable the default next action handling.
                }
            );
            const loadingToastId = toast.loading('Processing your payment...');

            promise.then((result: any) => {
                if (result.error) {
                    toast.update(loadingToastId, {
                        render: result.error.message || 'An error occurred.',
                        type: 'error',
                        isLoading: false,
                        autoClose: 5000
                    });
                } else {
                    toast.update(loadingToastId, {
                        render: 'Payment successful!',
                        type: 'success',
                        isLoading: false,
                        autoClose: 5000
                    });
                    setShow(false)
                    setPaymentSuccess(true);
                }
            }).catch((err: any) => {
                setError(err.message || 'An unexpected error occurred.');
                toast.update(loadingToastId, {
                    render: err.message || 'An unexpected error occurred.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000
                });
            });
        } catch (err) {
            setError((err as StripeError)?.message ?? 'An unexpected error occurred.');
            toast.error( `${err} test`);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {error && <span className="error">{error}</span>}
            <div className="mb-2">
                <label className={styles.label}>Card number</label>
                <CardNumberElement options={stripeOptions} className={styles.cardInput} />
            </div>
            <div className="mb-2">
                <label className={styles.label}>Expiration date</label>
                <CardExpiryElement options={stripeOptions} className={styles.cardInput} />
            </div>
            <div className="mb-2">
                <label className={styles.label}>CVC</label>
                <CardCvcElement options={stripeOptions} className={styles.cardInput} />
            </div>
            <button className={styles.pay} type="submit" disabled={!stripe}>Pay</button>
        </form>
    );
};

export default StripeForm;
