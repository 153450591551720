const LANGUAGE = {
    CHINESE:1,
    ENGLISH:2
}

const PAGESTATES = {
    CURRENT_PAGE : "currentPage",
    PAR_PAGE_RECORDS : "parPageRecords",
    COUPON_VALUE :"couponValue",
    COUPON_SUBMIT : "couponSubmit"
}



export {
    LANGUAGE,
    PAGESTATES
};